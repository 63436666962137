import { StrictMode, Suspense } from "react";

import "@vericus/cadmus-common/dist/style.css";
import { CssBaseline, CUIProvider } from "@vericus/cadmus-ui";
import "@vericus/cadmus-ui/dist/index.css";

import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";
import { ApolloProvider } from "@apollo/client/react";
import NiceModal from "@ebay/nice-modal-react";
import { JitsuProvider } from "@jitsu/react";
import {
  browserTracingIntegration,
  ErrorBoundary,
  init,
  replayIntegration,
} from "@sentry/react";
import "core-js/features/array/flat";
import "core-js/features/string/match-all";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { client as apolloClient } from "@/client/apollo";
import jitsuClient from "@/client/jitsu";
import { CrashError } from "@/components/errors";
import { LoadingPage } from "@/components/loading-page";
import reduxStore from "@/data/store";

import { DEPLOY_ENV, DEV, RELEASE_HASH, SENTRY_DSN } from "./config";
import { theme } from "./index.css";
import { routes } from "./routes";

import "mathlive/dist/mathlive-static.css";

if (DEV) {
  loadDevMessages();
  loadErrorMessages();
}

init({
  dsn: SENTRY_DSN,
  environment: DEPLOY_ENV,
  replaysOnErrorSampleRate: 0.8,
  integrations: [
    replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
    browserTracingIntegration(),
  ],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.8,
  release: RELEASE_HASH,
});

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <StrictMode>
    <CUIProvider theme={theme}>
      <ErrorBoundary fallback={<CrashError />}>
        <JitsuProvider client={jitsuClient}>
          <NiceModal.Provider>
            <Provider store={reduxStore}>
              <ApolloProvider client={apolloClient}>
                <>
                  <CssBaseline />
                  <BrowserRouter>
                    <Suspense fallback={<LoadingPage />}>{routes}</Suspense>
                  </BrowserRouter>
                </>
              </ApolloProvider>
            </Provider>
          </NiceModal.Provider>
        </JitsuProvider>
      </ErrorBoundary>
    </CUIProvider>
  </StrictMode>
);
