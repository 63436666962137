import { useCallback, useEffect } from "react";

import { useAppDispatch, useAppSelector } from "@/data/hooks";
import { footnoteSlice } from "@/features/footnote";
import { materialsSlice } from "@/features/materials";

import { useTabletOrientationModal } from "./use-tablet-orientation-modal";

export enum PanelType {
  Footnote = "footnote",
  Materials = "materials",
}

export interface PanelVisibilityState {
  isMaterialsPanelOpen: boolean;
  isFootnotePanelOpen: boolean;
  onOpenPanel: (item: PanelType) => void;
  onClosePanel: (item: PanelType) => void;
}

/**
 * This hook controls visibility of the left materials panel and the right
 * footnote panel in different media queries (screen sizes).
 *
 * For tablets in portrait mode, only one panel can be open at a time.
 *
 * Use this hook instead of the redux stores for the panel states.
 */
export function usePanelVisibility(): PanelVisibilityState {
  const dispatch = useAppDispatch();
  const { isTabletPortrait, isLoading: isTabletMediaQueryLoading } =
    useTabletOrientationModal();

  const { isOpen: isMaterialsPanelOpen } = useAppSelector(
    (state) => state.materials
  );
  const { isOpen: isFootnotePanelOpen } = useAppSelector(
    (state) => state.footnote
  );

  const onClosePanel = (panel: PanelType) => {
    switch (panel) {
      case PanelType.Footnote:
        dispatch(footnoteSlice.actions.close());
        break;
      case PanelType.Materials:
        dispatch(materialsSlice.actions.close());
        break;
    }
  };

  const onCloseAllPanels = useCallback(() => {
    dispatch(footnoteSlice.actions.close());
    dispatch(materialsSlice.actions.close());
  }, [dispatch]);

  const onOpenPanel = (panel: PanelType) => {
    if (isTabletPortrait) {
      // close opposite panel to ensure only one panel is open
      switch (panel) {
        case PanelType.Footnote:
          dispatch(materialsSlice.actions.close());
          break;
        case PanelType.Materials:
          dispatch(footnoteSlice.actions.close());
          break;
      }
    }
    switch (panel) {
      case PanelType.Footnote:
        dispatch(footnoteSlice.actions.open());
        break;
      case PanelType.Materials:
        dispatch(materialsSlice.actions.open());
        break;
    }
  };

  useEffect(() => {
    if (!isTabletMediaQueryLoading && isTabletPortrait) {
      onCloseAllPanels();
    }
  }, [isTabletMediaQueryLoading, onCloseAllPanels, isTabletPortrait]);

  return {
    isMaterialsPanelOpen,
    isFootnotePanelOpen,
    onOpenPanel,
    onClosePanel,
  };
}
