import { Editor } from "@vericus/cadmus-editor-prosemirror";

import { makeVar, useReactiveVar } from "@apollo/client";

import { AppDispatch } from "@/data/store";
import { AnswerBlockFragment, QuestionType } from "@/generated/graphql";

import { Snapshot } from "../snapshot";
import { setActiveEditor } from "./active-editor";
import { newAnswerEditor, newClassicEditor, SheetProps } from "./answer-editor";
import { ClassicEditorName } from "./types";

/**
 * Global singleton EditorStore.
 *
 * The Editor will contain all the Editor instances in the Work.
 *
 * They can come from different sources:
 *
 *   1. In Multi-Format, Extended Answer questions will have a corresponding
 *   answer editor.
 *
 *   2. In Classic, the editors created from AnswerBlocks with well-known
 *   `blockName` that matches the `ClassicClassicEditorName` enum.
 *
 *   3. In Classic, for BACKWARD COMPAT, if there are no AnswerBlocks fetched
 *   from the server, we will still create the default classic editors for
 *   "body", "notes", and "references".
 *
 */
export const editorStore = makeVar<EditorStore>({});

/**
 * Record of Work Editors.
 *
 * NOTE: The key should ideally be the server AnswerBlock ID. They can be an `ClassicEditorName`
 * value for backwards compatibility. These properties should not be relied
 * upon. Just consider it as a unique Editor ID in the store.
 */
export type EditorStore = Record<string, EditorStoreValue>;

/** Value stored in the EditorStore. */
interface EditorStoreValue {
  /** Editor instance. */
  editor: Editor;
  /** Optional classic Editor name. */
  name: ClassicEditorName | null;
}
/**
 * Reactive variable to indicate if the editor store has been loaded.
 *
 * We set this to true after the store is loaded. This is used to prevent the UI
 * from rendering before the store is ready. The loading of the Edito Store is
 * asynchronous.
 */
export const editorStoreLoaded = makeVar(false);

/**
 * Reactive variable storing the `EditorStoreNameMap`.
 */
export const classicEditorNameStoreMap = makeVar<ClassicEditorNameStoreMap>({
  [ClassicEditorName.Body]: null,
  [ClassicEditorName.Notes]: null,
  [ClassicEditorName.References]: null,
});

/**
 * Mapping of well-known classic editor names to the IDs in EditorStore (if they
 * exist).
 */
type ClassicEditorNameStoreMap = Record<ClassicEditorName, string | null>;

/** Hook to read the current loaded state for the global EditorStore. */
export function useEditorStoreLoaded() {
  return useReactiveVar(editorStoreLoaded);
}

/**
 * Hook to select the `Editor` instance from the global EditorStore.
 */
export function useEditorStoreEditor(editorId: string): Editor | null {
  const store = useReactiveVar(editorStore);
  return store[editorId]?.editor ?? null;
}

/**
 * Hook to select the `Editor` instance from the global EditorStore by its
 * well-known classic name.
 */
export function useNamedEditorStoreEditor(
  editorName: ClassicEditorName
): Editor | null {
  const editorId = classicEditorNameStoreMap()[editorName];
  const store = useReactiveVar(editorStore);
  if (!editorId) {
    return null;
  }
  return store[editorId]?.editor ?? null;
}

/**
 * Select the `Editor` instance for an Answer Block in the global Answer Store.
 */
export function selectEditorStoreEditor(editorId: string): Editor | null {
  const store = editorStore();
  return store[editorId]?.editor || null;
}

/**
 * Select the `Editor` instance for a named classic editor.
 */
export function selectNamedEditorStoreEditor(
  name: ClassicEditorName
): Editor | null {
  const nameMap = classicEditorNameStoreMap();
  const editorId = nameMap[name];
  if (!editorId) {
    return null;
  }
  const store = editorStore();
  return store[editorId]?.editor || null;
}

/** Hook to lookup registered Answer Block ID for a classic editor. */
export function useClassicAnswerBlockId(
  name: ClassicEditorName
): string | null {
  return useReactiveVar(classicEditorNameStoreMap)[name];
}

/**
 * Initialise the global Editor Store with the latest snapshots fetched from the
 * server.
 *
 * @param answerBlocks - The list of answer blocks to initialise the store with.
 * @param snapshots - The latest snapshots deserialised.
 * @param dispatch - Redux dispatcher
 * @param sheetProps - Properties of Sheet that affect editor options.
 *
 * @returns Promise that resolves when the loading is complete.
 */
export async function loadEditorStore(
  answerBlocks: AnswerBlockFragment[],
  snapshots: Record<string, Snapshot>,
  dispatch: AppDispatch,
  sheetProps: SheetProps
): Promise<void> {
  let store = editorStore();
  let classicNameMap = classicEditorNameStoreMap();

  // Create Editor instances from AnswerBlocks
  store = await loadAnswerBlocksIntoStore(
    store,
    answerBlocks,
    snapshots,
    dispatch,
    sheetProps
  );

  // Reverse mapping of editorName to the AnswerBlock ID
  classicNameMap = loadClassicNames(classicNameMap, store);

  // In Classic, ensure there are "body", "notes", and "references" editor for
  // backwards compatibility. Ideally they are represented as AnswerBlocks
  // anyways. If they have not been loaded from the AnswerBlocks, we will have
  // to lookup the decoded classic Save's snapshots and load the classic
  // editors.
  if (!sheetProps.isMultiFormat) {
    const classicEditorsLoaded =
      classicNameMap[ClassicEditorName.Body] !== null &&
      classicNameMap[ClassicEditorName.Notes] !== null &&
      classicNameMap[ClassicEditorName.References] !== null;

    if (!classicEditorsLoaded) {
      store = await loadClassicEditorsIntoStore(
        store,
        snapshots,
        dispatch,
        sheetProps
      );
      classicNameMap = loadClassicNames(classicNameMap, store);
    }
  }

  if (!sheetProps.isMultiFormat && classicNameMap[ClassicEditorName.Body]) {
    const activeBlockId = classicNameMap[ClassicEditorName.Body];
    const activeEditor = store[activeBlockId]?.editor;
    if (activeEditor) {
      setActiveEditor(activeBlockId, activeEditor, ClassicEditorName.Body);
    }
  }

  // Update all the reactive variables
  editorStore(store);
  classicEditorNameStoreMap(classicNameMap);
  editorStoreLoaded(true);
}

/**
 * Update the `store` with Answer Editors sourced from the `answerBlocks`.
 *
 * An `AnswerBlock` can be linked to a `question` or it can be standalone with a
 * classic `blockName`. Editors will be created for Extended question linked
 * AnswerBlocks and these classic named AnswerBlocks.
 *
 * Other AnswerBlocks will be ignored.
 *
 * The latest snapshot of the AnswerBlock is expected in the `snapshots` record
 * with the keys being the Answer Block ID. The initial editor contents will be
 * loaded using that.
 */
async function loadAnswerBlocksIntoStore(
  store: EditorStore,
  answerBlocks: AnswerBlockFragment[],
  snapshots: Record<string, Snapshot>,
  dispatch: AppDispatch,
  sheetProps: SheetProps
): Promise<EditorStore> {
  let newStore = { ...store };
  for (const answerBlock of answerBlocks) {
    if (newStore[answerBlock.id]) {
      continue;
    }

    // Create Editor instance for an extended answer block
    if (answerBlock.question?.questionType === QuestionType.Extended) {
      const snapshot = snapshots[answerBlock.id];
      const editor = await newAnswerEditor({
        answerBlockId: answerBlock.id,
        editorName: null,
        content: snapshot?.answerDoc ?? null,
        version: snapshot?.version ?? 0,
        dispatch,
        sheetProps,
        editorOpts: {
          enableHyperlink: !sheetProps.isLockDownExam,
          enableImage: !sheetProps.isLockDownExam,
        },
      });
      newStore = {
        ...newStore,
        [answerBlock.id]: { editor, name: null },
      };
    }

    // Create Editor instance for a named classic answer block
    const editorName = blockNameToClassicEditorName(answerBlock.blockName);
    if (editorName) {
      const editor = await newClassicEditor(
        editorName,
        answerBlock.id,
        snapshots[answerBlock.id] ?? null,
        dispatch,
        sheetProps
      );
      newStore = {
        ...newStore,
        [answerBlock.id]: { editor, name: editorName },
      };
    }
  }

  return newStore;
}

function blockNameToClassicEditorName(
  blockName: string | null
): ClassicEditorName | null {
  switch (blockName) {
    case "body":
      return ClassicEditorName.Body;
    case "notes":
      return ClassicEditorName.Notes;
    case "references":
      return ClassicEditorName.References;
    default:
      return null;
  }
}

async function loadClassicEditorsIntoStore(
  store: EditorStore,
  snapshots: Record<string, Snapshot>,
  dispatch: AppDispatch,
  sheetProps: SheetProps
): Promise<EditorStore> {
  let newStore = { ...store };

  const classicEditorNames = [
    ClassicEditorName.Body,
    ClassicEditorName.References,
    ClassicEditorName.Notes,
  ];

  for (const editorName of classicEditorNames) {
    if (newStore[editorName]) {
      continue;
    }
    const editor = await newClassicEditor(
      editorName,
      editorName,
      snapshots[editorName] ?? null,
      dispatch,
      sheetProps
    );
    newStore = {
      ...newStore,
      [editorName]: { editor, name: editorName },
    };
  }

  return newStore;
}

function loadClassicNames(
  nameMap: ClassicEditorNameStoreMap,
  editorStore: EditorStore
): ClassicEditorNameStoreMap {
  return Object.entries(editorStore).reduce((acc, [answerBlockId, value]) => {
    switch (value.name) {
      case ClassicEditorName.Body:
        return { ...acc, [ClassicEditorName.Body]: answerBlockId };
      case ClassicEditorName.References:
        return { ...acc, [ClassicEditorName.References]: answerBlockId };
      case ClassicEditorName.Notes:
        return { ...acc, [ClassicEditorName.Notes]: answerBlockId };
      default:
        return acc;
    }
  }, nameMap);
}

/** Run a function on all editors and ignore the result. */
export function forAllEditors(fn: (editor: Editor, editorId: string) => void) {
  const store = editorStore();
  Object.entries(store).forEach(([answerBlockId, { editor }]) => {
    fn(editor, answerBlockId);
  });
}
