import { Snapshot } from "@/stores/snapshot";

export enum ConnectionStatus {
  CONNECTED = "CONNECTED",
  DISCONNECTED = "DISCONNECTED",
}

/**
 * App wide saving mechanism.
 */
export enum SavingMechanism {
  /**
   * Classic full saving that loads and persists editors as a `Save`.
   * The mutations for persisting are `save` and `submit`.
   */
  FULL = "FULL",
  /**
   * New Block based persistence that loads and saves Work contents as Answer
   * Blocks. The Answer Blocks can be a classic editor with a well-known
   * `block_name`, an editor for extended answer question, or a block for other
   * types of questions.
   *
   * Each block is saved independently as a `BlockSnapshot` using
   * `saveBlockSnapshot` mutation. They are submitted using `submitV2` mutation
   * that links a `Submission` with Block Snapshots instead of a `Save`.
   */
  BLOCK = "BLOCK",
}

export interface AuthorityState {
  /** Blanket work lock on the current session to prevent Save inconsistencies. */
  sessionLock: boolean;

  /** Computed saving mechanism. */
  savingMechanism: SavingMechanism;

  /**
   * The current work ID.
   */
  workId?: string;

  /** Work has a draft submission. */
  hasDraft: boolean;

  /** Work has a final submission. */
  hasFinal: boolean;

  /** The last save request has an error */
  saveError: SaveError | null;

  /** Number of attempts the save request queue is at. Starts with 0. */
  saveAttempt: number;

  /** Save requests' Redux requestId that are still in flights*/
  savesInFlight: string[];

  /** The current status of the connection to the server */
  connectionStatus: ConnectionStatus;

  /**
   * Whether the student has accepted the academic integrity
   * submission declaration.
   */
  hasAcceptedSubmissionDeclaration: boolean;

  /** A submit request is in flight. */
  submitLoading: boolean;
  /** The last submit request has errored out. */
  submitError: SubmitError | null;
  /** Show a submission preview page. */
  submitPreview: boolean;
  /** Show a timed submit prompt locking the submission date. */
  submitPromptOpened: string | null;

  /** Local record of all latest answer snapshots */
  snapshots: Record<string, Snapshot>;

  /** Queue of unsaved Answer blocks and their snapshots */
  pendingSnapshots: PendingSnapshot[];
}

export interface SubmitError {
  kind: "validation" | "network";
  title: string;
  detail?: string;
}

export interface SaveError {
  kind: "network" | "already_reported";
  title: string;
  detail?: string;
}

/** Snapshot belonging to an Answer Block. */
export interface PendingSnapshot {
  /** The answer block id to save the snapshot to. */
  answerBlockId: string;
  /** Snapshot state */
  snapshot: Snapshot;
}
