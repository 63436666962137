import { PendingSnapshot } from "../authority";

export function pendingSnapshotParser(value: string): PendingSnapshot | null {
  try {
    const parsed = JSON.parse(value);
    if (
      parsed &&
      typeof parsed === "object" &&
      typeof parsed.answerBlockId === "string" &&
      parsed.snapshot
    ) {
      return parsed;
    }
    return null;
  } catch (_err) {
    return null;
  }
}
