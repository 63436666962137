import { createSlice } from "@reduxjs/toolkit";

/** Footnote redux state */
export interface FootnoteState {
  /** Open state of the footnote sidebar */
  isOpen: boolean;
}

const initialState: FootnoteState = {
  isOpen: true,
};

export const footnoteSlice = createSlice({
  name: "footnote",
  initialState,
  reducers: {
    open: (state) => {
      state.isOpen = true;
    },
    close: (state) => {
      state.isOpen = false;
    },
  },
});

// Export Actions
export const { open, close } = footnoteSlice.actions;

// Export Reducer
export const footnoteReducer = footnoteSlice.reducer;
