import { client } from "@/client/apollo";
import { __GLOBAL_SESSION_ID } from "@/client/globals";
import {
  EditorType,
  LatestBlockSnapshotFragment,
  LatestBlockSnapshotFragmentDoc,
  SaveBlockSnapshotDocument,
  SaveBlockSnapshotMutation,
  SaveDocument,
  SaveMetadataInput,
  SaveMutation,
  SubmissionFragmentDoc,
  SubmissionType,
  SubmitDocument,
  SubmitMutation,
  SubmitMutationVariables,
} from "@/generated/graphql";
import { getCurrentDate } from "@/utils/datetime";

/**
 * Cloud save mutation to save all editors for the current Work.
 *
 * The `dispatchedAt` variable is a client-side generated timestamp but using a
 * server time-sync interface.
 *
 * @param workId the current student Work ID to save to
 * @param content serialised content of the save document
 * @param metadata extra information sent to the server for logging
 * @return graphql mutation promise
 */
export function onCloudSave(
  workId: string,
  content: string,
  metadata: SaveMetadataInput
) {
  return client.mutate<SaveMutation>({
    mutation: SaveDocument,
    variables: {
      editor: EditorType.Prosemirror,
      workId,
      content,
      dispatchedAt: getCurrentDate().toISOString(),
      metadata,
    },
  });
}

/**
 * Write a Answer Block snapshot.
 *
 * @param workId the current student Work ID
 * @param answerBlockid the Answer Block being written
 * @param content serialised snapshot content
 * @param version incremental version number
 * @return graphql mutation promise
 */
export function onSnapshot(
  workId: string,
  answerBlockId: string,
  content: string,
  version: number,
  previousBlockSnapshotId: string | null
) {
  return client.mutate<SaveBlockSnapshotMutation>({
    mutation: SaveBlockSnapshotDocument,
    variables: {
      workId,
      answerBlockId,
      snapshotContent: content,
      version,
      previousBlockSnapshotId,
    },
    update: (cache, { data: result }) => {
      const snapshot = result?.saveBlockSnapshot.blockSnapshot;
      if (snapshot) {
        cache.writeFragment<LatestBlockSnapshotFragment>({
          fragment: LatestBlockSnapshotFragmentDoc,
          fragmentName: "LatestBlockSnapshot",
          data: {
            __typename: "AnswerBlock",
            id: answerBlockId,
            latestBlockSnapshot: {
              __typename: "BlockSnapshot",
              id: snapshot.id,
              cacheId: `${answerBlockId}:latest_snapshot`,
              answerBlockId,
              version: version,
              content: content,
              blockName: snapshot.blockName,
            },
          },
        });
      }
    },
  });
}

/**
 * Submit mutation for both Draft and Final submissions.
 */
export function onSubmit(
  workId: string,
  saveId: string,
  type: SubmissionType,
  submittedAt: string
) {
  return client.mutate<SubmitMutation, SubmitMutationVariables>({
    mutation: SubmitDocument,
    variables: {
      editor: EditorType.Prosemirror,
      workId,
      saveId,
      type,
      submittedAt,
      acceptedDeclaration: true,
      sessionId: __GLOBAL_SESSION_ID.current,
    },
    // If there are existing `Submission` objects in the Query, they will
    // normally get overwritten. But if there are no existing submissions,
    // then the incoming `Submission` result will be orphaned and not
    // available unless directly queried.
    //
    // Therefore we must ensure the `Submission` objects are explicitely added
    // to the `Query` cache at the `$.work.final`and `$.work.draft` fields.
    update: (cache, { data: result }) => {
      if (result && result.submit) {
        const newSubmissionRef = cache.writeFragment({
          data: result.submit,
          fragment: SubmissionFragmentDoc,
          fragmentName: "Submission",
        });
        if (newSubmissionRef) {
          cache.modify({
            id: "Work:{}",
            fields: {
              draft(existingRef) {
                if (type === SubmissionType.Draft) {
                  return newSubmissionRef;
                }
                return existingRef;
              },
              final(existingRef) {
                if (type === SubmissionType.Final) {
                  return newSubmissionRef;
                }
                return existingRef;
              },
            },
          });
        }
      }
    },
  });
}
