import { createContext, useContext } from "react";

interface WorkContextValue {
  tenant: string;
  assessmentId: string;
  workId: string;
}

/**
 * Context for the current work being viewed.
 *
 * This context is a static context that marks the React tree underneath is for
 * a specific work.
 *
 * NOTE this context must be accessed under the `WorkContextProvider`.
 */
export const WorkContext = createContext<WorkContextValue>({
  tenant: "",
  assessmentId: "",
  workId: "",
});

/**
 * Read Work metadata for the current contextual work.
 */
export function useWorkContext(): WorkContextValue {
  return useContext(WorkContext);
}
