import { useMemo } from "react";

import { useAppSelector } from "@/data/hooks";
import { QuestionType } from "@/generated/graphql";
import { useAnswerBlocks } from "@/graphql/hooks";
import { useWorkContext } from "@/stores/work-context";

import { selectIncompleteAnswerIds } from "../answer-slice";

const EXCLUDED_TYPES_FOR_REVIEW = [
  QuestionType.Section,
  QuestionType.Overview,
  QuestionType.Sub,
];

/**
 * Selects all answers that are reviewable by the current student.
 */
export function useReviewableAnswers(): ReviewableAnswer[] {
  const { workId } = useWorkContext();
  const answerBlocks = useAnswerBlocks(workId);
  const incompleteAnswers = useAppSelector(selectIncompleteAnswerIds);
  return useMemo(() => {
    return incompleteAnswers.flatMap((id) => {
      const block = answerBlocks.find((b) => b.id === id);
      const questionType = block?.question?.questionType;
      if (
        block &&
        questionType &&
        !EXCLUDED_TYPES_FOR_REVIEW.includes(questionType)
      ) {
        return [
          {
            answerBlockId: block.id,
            title: `Question ${block?.orderLabel}`,
            bookmarked: block?.bookmark ?? undefined,
            skipped: block?.skip ?? undefined,
          },
        ];
      }
      return [];
    });
  }, [answerBlocks, incompleteAnswers]);
}

/** A Reviewable question item. */
export interface ReviewableAnswer {
  /** Answer Block ID */
  answerBlockId: string;
  /** The title of the question. */
  title: string;
  /** Whether the question is bookmarked. */
  bookmarked?: boolean;
  /** Whether the question is skipped */
  skipped?: boolean;
}
