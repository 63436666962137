import { wrap } from "comlink";

// eslint-disable-next-line import/default
import Worker from "@/features/timeline/date-notifier/date-notifier.worker?worker";

import DateNotifier from "./date-notifier";

const worker = new Worker();

// Set up the Global Web Worker.
//
// This Comlink proxy is the main thread proxy for a RPC like interface with the
// Worker code.
export const DateNotifierWorkerProxy = wrap<typeof DateNotifier>(worker);
