import { useEffect, useRef, useState } from "react";

import { boolParser, getItem, setItem } from "@/utils/localStorage";

import { useDeviceResolution } from "./use-device-resolution";

const PREFIX = "tabletWarning";

interface ModalState {
  /** If the modal has been dismissed */
  isDismissed: boolean;
  /** If the media query is still loading */
  isLoading: boolean;
  /** If the modal should be opened */
  shouldOpen: boolean;
  /** If the tablet is in portrait mode */
  isTabletPortrait: boolean;
  /** Function to close the modal */
  onClose: () => void;
}

/**
 * Hook for controlling the apparition of a modal that warns the user about the tablet orientation.
 *
 * ## Effects
 *
 * First effect that tracks if the modal has been shown
 *
 * Second effect considers the case where the modal has been shown, but the user
 * has changed the orientation, in this case the modal should be dismissed.
 */
export function useTabletOrientationModal(): ModalState {
  const storageDismissedValue = !!getItem(PREFIX, "dismissed", boolParser);
  const [isDismissed, setIsDismissed] = useState(storageDismissedValue);
  const hasBeenShown = useRef(false);

  const { isTabletAspectRatio, isTabletResolution } = useDeviceResolution();

  const isLoading =
    isTabletAspectRatio === undefined || isTabletResolution === undefined;

  const shouldOpen = (isTabletAspectRatio &&
    isTabletResolution &&
    !isDismissed) as boolean;

  const onClose = () => {
    setItem(PREFIX, "dismissed", "true");
    setIsDismissed(true);
    hasBeenShown.current = true;
  };

  useEffect(() => {
    if (shouldOpen) {
      hasBeenShown.current = true;
    }
  }, [shouldOpen]);

  useEffect(() => {
    if (
      hasBeenShown.current &&
      !isTabletAspectRatio &&
      !isTabletResolution &&
      !isDismissed &&
      !isLoading
    ) {
      onClose();
    }
  }, [isTabletAspectRatio, isTabletResolution, isDismissed, isLoading]);

  return {
    isDismissed,
    isLoading,
    shouldOpen,
    isTabletPortrait: !!(isTabletAspectRatio && isTabletResolution),
    onClose,
  };
}
