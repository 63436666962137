import { wrap } from "comlink";

// eslint-disable-next-line import/default
import Worker from "@/features/timeline/use-countdown/countdown.worker?worker";

import { Countdown } from "./countdown";

const worker = new Worker();

// Set up the Global Web Worker.
//
// This Comlink proxy is the main thread proxy for a RPC like interface with the
// Worker code.
export const CountdownProxy = wrap<typeof Countdown>(worker);
