import { RefObject, useCallback } from "react";

import { ManualModal, ManualTriggers } from "@vericus/cadmus-manual";

import { AssessmentFragment, UserFragment } from "generated/graphql";

interface ManualWithMutationsProps {
  student: UserFragment;
  assessment: AssessmentFragment;
  mountElement: RefObject<HTMLElement>;
  trigger?: JSX.Element;
}

export function ManualWithMutations(props: ManualWithMutationsProps) {
  const { student, assessment, mountElement, trigger } = props;
  // Open Hubspot form with prefilled fields through query params
  const onAskHelp = useCallback(() => {
    const firstName =
      student.givenName === ""
        ? student.name.split(" ")[0] ?? ""
        : student.givenName;
    const lastName =
      student.familyName === ""
        ? student.name.split(" ").splice(1).join("")
        : student.familyName;

    const formURL = new URL("https://support.cadmus.io/kb-tickets/new");
    formURL.searchParams.append("firstname", firstName);
    formURL.searchParams.append("lastname", lastName);
    formURL.searchParams.append("email", student.email ?? "");
    formURL.searchParams.append("requester", "Student");
    formURL.searchParams.append("assessment_name", assessment.name);
    formURL.searchParams.append("subject_code___name", assessment.subjectName);

    window.open(formURL.toString(), "_blank")?.focus?.();
  }, [student, assessment]);

  return (
    <ManualModal
      onAskHelp={onAskHelp}
      mountElement={mountElement}
      trigger={trigger ? trigger : <ManualTriggers.CircularTrigger />}
    />
  );
}
