import { client } from "client/apollo";

import { SheetDocument, SheetQuery } from "@/generated/graphql";

/**
 * Fetch the Work's Sheet using a graphql query which ignores the cache.
 */
export function refetchSheet(workId: string) {
  return client.query<SheetQuery>({
    query: SheetDocument,
    variables: {
      workId,
    },
    fetchPolicy: "network-only",
  });
}
