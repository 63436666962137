import { QuestionPreviewData } from "@vericus/cadmus-common";
import { JSONContent } from "@vericus/cadmus-editor-prosemirror";

import {
  BaseType,
  QuestionFieldFragment,
  QuestionType,
} from "@/generated/graphql";
import { Snapshot } from "@/stores/snapshot";

import { shuffleWithSeed } from "./shuffle-with-seed";

export function mapQuestionType(
  questionType: QuestionType
): QuestionPreviewData["question_type"] {
  switch (questionType) {
    case QuestionType.Mcq:
      return "mcq";
    case QuestionType.Matching:
      return "matching";
    case QuestionType.Short:
      return "short";
    case QuestionType.Truefalse:
      return "truefalse";
    case QuestionType.Blanks:
      return "blanks";
    case QuestionType.Numerical:
      return "numerical";
    case QuestionType.Overview:
      return "overview";
    case QuestionType.Sub:
      return "sub";
    case QuestionType.Hotspot:
      return "hotspot";
    default:
      return "extended";
  }
}

export function mapFieldInteraction(
  questionType: QuestionType | undefined,
  interaction: QuestionFieldFragment["interaction"],
  shuffle: boolean | undefined,
  answerBlockId: string
) {
  switch (interaction.__typename) {
    case "ChoiceInteraction":
      return {
        kind: "choice_interaction" as const,
        label: interaction.label,
        choices:
          shuffle && questionType !== QuestionType.Truefalse
            ? shuffleWithSeed(interaction.choices, answerBlockId)
            : interaction.choices,
      };

    case "TextEntryInteraction":
      return {
        kind: "text_entry_interaction" as const,
        expected_length: interaction.expectedLength,
        base_type: interaction.baseType ?? BaseType.String,
      };

    case "ExtendedInteraction":
      return {
        kind: "extended_interaction" as const,
        word_limit: interaction.wordLimit,
      };
    
    case "PositionObjectInteraction":
      return {
        kind: "position_object_interaction" as const,
        label: interaction.label,
        max_choices: interaction.maxChoices,
      };

    case "MatchInteraction":
      return {
        kind: "match_interaction" as const,
        target_set: interaction.targetSet,
        source_set: interaction.sourceSet,
      };

    default:
      return {
        kind: "extended_interaction" as const,
        word_limit: null,
      };
  }
}

export function mapSnapshotToAnswer(
  snapshot: Snapshot | null,
  questionType: QuestionType
) {
  const answer: QuestionPreviewData["answer"] = {
    version: snapshot?.version ?? 0,
  };

  switch (questionType) {
    case QuestionType.Truefalse:
      answer.answer_boolean = snapshot?.answerBoolean;

      break;

    case QuestionType.Matching:
      answer.answer_fields = snapshot?.answerFields;
      break;

    case QuestionType.Mcq:
      answer.answer_choice_ids =
        snapshot?.answerChoiceIds !== undefined
          ? snapshot.answerChoiceIds
          : undefined;
      break;

    case QuestionType.Short:
      answer.answer_short = snapshot?.answerShort;
      break;

    case QuestionType.Numerical:
      answer.answer_fields = snapshot?.answerFields;
      break;

    case QuestionType.Blanks:
      answer.answer_blanks = snapshot?.answerBlanks;
      break;
    
    case QuestionType.Hotspot:
      answer.answer_fields = snapshot?.answerFields;
      break;

    case QuestionType.Extended:
      answer.answer_doc = snapshot?.answerDoc as JSONContent | undefined;
      break;
  }

  return answer;
}
