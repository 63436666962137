import { ReactNode, useEffect, useRef, useState } from "react";

import {
  ImperativePanelHandle,
  Panel,
  PanelResizeHandle,
} from "react-resizable-panels";

import { useAppSelector } from "@/data/hooks";
import { PanelType, usePanelVisibility } from "@/hooks/use-panel-visibility";

import { PanelControl } from "./panel-control";
import * as styles from "./resizable-panel.css";

interface MaterialsResizablePanelProps {
  children: ReactNode;
  /** Whether the panel should have lower opacity. */
  isLowerOpacity?: boolean;
}

export function MaterialsResizablePanel(props: MaterialsResizablePanelProps) {
  const { children, isLowerOpacity } = props;
  const {
    isMaterialsPanelOpen: isOpen,
    onClosePanel,
    onOpenPanel,
  } = usePanelVisibility();

  // Open Panel state is controlled by the global redux state
  const { tab } = useAppSelector((state) => state.materials);

  // Ref to get access to the resizable panel's imperative methods
  const panelRef = useRef<ImperativePanelHandle>(null);
  const toggleOpen = () => {
    if (panelRef.current) {
      if (isOpen) {
        panelRef.current.collapse();
      } else {
        panelRef.current.expand();
        onOpenPanel(PanelType.Materials);
      }
    }
  };

  // Expand the panel in case it was globally opened
  useEffect(() => {
    if (panelRef.current && isOpen && panelRef.current.isCollapsed()) {
      panelRef.current.expand();
    }

    if (panelRef.current && !isOpen && panelRef.current.isExpanded()) {
      panelRef.current.collapse();
    }
  }, [isOpen]);

  // Used to mirror the resizing state of the panel to add a transition effect
  const [isResizing, setIsResizing] = useState(false);

  return (
    <>
      <Panel
        id="materials-panel"
        className={styles.panel}
        style={{
          // transition to make collapsing/opening smoother, but not slowing
          // resizing down
          transition: isResizing ? "none" : "flex 0.3s ease",
          // to make close button visible
          overflow: isOpen ? "hidden" : "visible",
        }}
        order={1}
        collapsible={true}
        defaultSize={34}
        minSize={25}
        maxSize={62}
        ref={panelRef}
        collapsedSize={2}
        onCollapse={() => onClosePanel(PanelType.Materials)}
        onExpand={() => onOpenPanel(PanelType.Materials)}
      >
        <div
          className={styles.content}
          style={{
            width: isOpen ? "100%" : 0,
            opacity: isLowerOpacity ? 0.54 : 1,
          }}
          id={`materials-${tab}`}
        >
          {children}
        </div>
        <div className={styles.track}>
          <PanelControl isOpen={isOpen} tab={tab} onClick={toggleOpen} />
        </div>
      </Panel>
      <PanelResizeHandle
        className={styles.handle}
        onDragging={(e) => setIsResizing(e)}
      />
    </>
  );
}
