import { useMemo } from "react";
import { styled } from "styled-components";

import { Button, Spacer, Text } from "@vericus/cadmus-ui";

import { ResultFragment, SubmissionType } from "generated/graphql";
import { formatDay, getCurrentDate, isBeforeDate } from "utils/datetime";

import gradeIcon from "@/assets/icons/grade.svg";
import similarityIcon from "@/assets/icons/similarity.svg";
import { useHeraResultLaunch } from "@/features/submission";

import { DataBlock } from "./DataBlock";

interface Props {
  /** Pantheon gradebook Result with integration data. */
  result: ResultFragment;
  /** Submission type for which this result is displayed. */
  submissionType: SubmissionType;
  /** Feedback & Grade return date for the submission type. */
  returnDate: Date;
  /** Is the student allowed to view the similarity score? */
  canViewReport: boolean;
  /** Is the student allowed to make re-submissions? */
  canResubmit: boolean;
}

export function TurnitinView(props: Props) {
  const { result, submissionType, canViewReport, canResubmit, returnDate } =
    props;
  const { grade, similarity } = result;
  const similarityScore = similarity && similarity.display;

  // Handler to launch a new tab for Turnitin access (through Pantheon and hera)
  const handleLaunch = useHeraResultLaunch(result, "turnitin");

  // Memoised generation of button and message texts
  const { button, message } = useMemo(() => {
    const isDraft = submissionType === SubmissionType.Draft;
    const isFinal = submissionType === SubmissionType.Final;
    const isBeforeReturnDate = returnDate ? isBeforeDate(returnDate) : false;
    const prettyReturnDate = formatDay(returnDate || getCurrentDate());

    // Displayed status message
    let message = "";
    // Final submission message
    // There is no similarity score yet and the student can view the score
    if (canViewReport && similarityScore === null) {
      if (isDraft && !isBeforeReturnDate) {
        message =
          "A Turnitin Similarity Report is being prepared for this submission... If your teacher provides feedback on this draft, you can access it above. ";
      } else {
        message =
          "A Turnitin Similarity Report is being prepared for this submission... ";
      }
    } else if (isDraft && isBeforeReturnDate) {
      // No feedback and the next event is draft feedback return
      message = `If your teacher provides feedback on this draft, you can access it after ${prettyReturnDate}. `;
    } else if (isDraft) {
      // No feedback and the next event is draft feedback return
      message = `If your teacher provides feedback on this draft, you can access it above. `;
    } else if (isBeforeReturnDate) {
      // No grade and the next event is final grade return.
      message = `Feedback will be available after ${prettyReturnDate}. `;
    }

    // Appending the resubmission suffix
    if (canResubmit && canViewReport && isBeforeReturnDate) {
      message +=
        "After 3 resubmissions, you'll need to wait 24 hrs to see your Similarity Score.";
    }

    // Button inner text
    let button = null;
    if (canViewReport && similarityScore !== null && isBeforeReturnDate) {
      button = "View similarity report";
    } else if (isFinal && !isBeforeReturnDate && grade !== null) {
      button = "View grades and feedback";
    } else if (!isBeforeReturnDate) {
      button = "View feedback";
    }

    return { button, message };
  }, [
    submissionType,
    canViewReport,
    canResubmit,
    similarityScore,
    grade,
    returnDate,
  ]);

  return (
    <Container>
      <Values>
        {submissionType === SubmissionType.Final && (
          <DataBlock
            imgSrc={gradeIcon}
            imgAlt=""
            data={
              grade === null || (returnDate && isBeforeDate(returnDate)) ? (
                "—"
              ) : (
                <>
                  {grade.display}{" "}
                  <Text kind="headingSix" as="span">
                    / {grade.max}
                  </Text>
                </>
              )
            }
            info="grade"
          />
        )}
        {canViewReport && (
          <DataBlock
            imgSrc={similarityIcon}
            imgAlt=""
            data={
              canViewReport && similarityScore !== null
                ? `${similarityScore}%`
                : "—"
            }
            info="similarity score"
          />
        )}
      </Values>

      {button && (
        <>
          <Spacer spacing={27} />
          <Button
            kind="secondary"
            onClick={handleLaunch}
            disabled={handleLaunch === undefined}
          >
            {button}
          </Button>
        </>
      )}

      {message !== "" && (
        <>
          <Spacer spacing={18} />
          <Text kind="systemMd" textAlign="center" title="Message">
            {message}
          </Text>
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  animation-delay: 0.9s;
  animation-fill-mode: backwards;
`;

const Values = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
`;
