import { useMediaQuery } from "@mantine/hooks";

export interface MobileOrientation {
  // If the resolution is below 600px
  isMobileResolution: boolean;
  // If the resolution is between 600px and 960px
  isTabletResolution: boolean;
  // If the tablet has a common aspect ratio
  isTabletAspectRatio: boolean;
  // If the resolution is below 960px
  isMobileOrTablet: boolean;
  // If the media query is still loading
  isLoading: boolean;
}

/**
 * Hook for detecting tablet and mobile orientation/resolution.
 *
 *   1. `isTabletPortrait` is a boolean that checks if the aspect ratio is
 *   within [3/4, 2/3, 10/16] with tolerance these are common tablet aspect
 *   ratios.
 *
 *   2. `isTabletResolution` checks if the resolution is within [600px, 960px]
 *   which is a common interval of tablet resolution.
 *
 *   3. `isMobileResolution` checks if the resolution is below 600px
 *
 */
export function useDeviceResolution(): MobileOrientation {
  const isTabletResolution = useMediaQuery(
    "(max-width:960px) and (min-width:600px)"
  );

  const isMobileResolution = useMediaQuery("(max-width:601px)");

  const isTabletAspectRatio = useMediaQuery(
    `(
        (min-aspect-ratio: 0.70/1) and (max-aspect-ratio: 0.80/1)
      ) or (
        (min-aspect-ratio: 0.575/1) and (max-aspect-ratio: 0.675/1)
      ) or (
        (min-aspect-ratio: 0.6167/1) and (max-aspect-ratio: 0.7167/1)
      )`
  );

  const isMobileOrTablet = !!isMobileResolution || !!isTabletResolution;

  const isLoading =
    isTabletResolution === undefined ||
    isMobileResolution === undefined ||
    isTabletAspectRatio === undefined;

  return {
    isMobileOrTablet,
    isMobileResolution: !!isMobileResolution,
    isTabletResolution: !!isTabletResolution,
    isTabletAspectRatio: !!isTabletAspectRatio,
    isLoading,
  };
}
