import { CursorPresence, UserCursor } from "@vericus/cadmus-editor-prosemirror";

import { Presence } from "phoenix";

/**
 * Implementation of the collaborative CursorPresence for a single Editor.
 *
 * Subscribes to the the Work Channel presence updates for changes to all Work
 * user's latest selection within a Connected Editor.
 *
 * A Connected editor is one that is connected to a collaborative
 * AuthorityStream and publishing it's steps and selection changes on that
 * stream with conflict resolution.
 */
export class LocalCursorPresence implements CursorPresence {
  state: UserCursor[] = [];
  streamId: string;
  presence: Presence;

  constructor(streamId: string, presence: Presence) {
    this.presence = presence;
    this.streamId = streamId;
  }

  cursors() {
    return this.state;
  }

  subscribe(cb: () => void): () => void {
    this.presence.onSync(() => {
      const cursors: UserCursor[] = [];
      this.presence.list((_id, { metas }: { metas: PresenceMeta[] }) => {
        for (const meta of metas) {
          if (meta.cursor && meta.cursor.stream_id === this.streamId) {
            cursors.push({
              clientId: meta.cursor.client_id,
              name: meta.cursor.name,
              colourset: meta.cursor.colourset,
              head: meta.cursor.selection.anchor,
            });
          }
        }
      });
      if (cursors.length > 0) {
        this.state = cursors;
        cb();
      }
    });

    return () => {};
  }
}

/** Shape of presence metadata on the Work Channel. */
interface PresenceMeta {
  cursor?: CursorMeta;
}

/** Cursor metadata part of a User's presence information.  */
interface CursorMeta {
  stream_id: string;
  client_id: string;
  colourset: number;
  name: string;
  selection: {
    anchor: number;
    head: number;
  };
}
