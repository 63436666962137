import { DBSchema, IDBPDatabase, openDB } from "idb";

import { AppDispatch } from "@/data/store";
import { PendingSnapshot, setPendingSnapshot } from "@/features/authority";
import { Snapshot } from "@/stores/snapshot";
import { removeItem, setItem } from "@/utils/localStorage";

import { pendingSnapshotParser } from "./utils";

export const IDB_PENDING_STORE_NAME = "pending-snapshots";

interface PendingDB extends DBSchema {
  [IDB_PENDING_STORE_NAME]: {
    key: [string, string]; // [workId, answerBlockId]
    value: PendingSnapshot & { idbKey: [string, string] };
  };
}

export enum BrowserStorageState {
  NOT_INITIALISED,
  LOCAL_STORAGE,
  INDEXED_DB,
  NONE,
}

/**
 * Manages browser storage for answer block snapshots to prevent data loss.
 *
 * Uses IndexedDB if supported, otherwise falls back to Local Storage. Stores
 * snapshots to recover student work if the connection is lost and the tab is
 * closed.
 *
 * All methods are no-op if the browser storage is not available or not
 * initialised.
 */
export class BrowserStorage {
  private state: BrowserStorageState;
  public idb: IDBPDatabase<PendingDB> | undefined;

  /**
   * The version of the IndexedDB schema.
   * Change this version to trigger the upgrade event on existing IndexedDB
   * (in case we want to change the schema of the db).
   */
  private idbVersion: number;

  /**
   * The work ID is used as a prefix for the keys in the browser storage.
   */
  private workId: string;

  /**
   * Whether the current exam is a LockDown exam.
   */
  private isLockDown: boolean;

  constructor({ isLockDown, workId }: { isLockDown: boolean; workId: string }) {
    this.idbVersion = 1;
    this.workId = workId;
    this.isLockDown = isLockDown;
    this.state = BrowserStorageState.NOT_INITIALISED;
  }

  getState() {
    return this.state;
  }

  disable() {
    this.state = BrowserStorageState.NONE;
  }

  /**
   * Initializes the connection with IndexedDB. If it's LockDownExam or IDB
   * fails, checks Local Storage availability.
   *
   * Doesn't throw an error if any of these browser storages is not available,
   * but sets the internal state to None.
   *
   * Has a timeout to prevent long/infinite loading.
   */
  async initialise() {
    if (!this.isLockDown) {
      this.idb = await openDB<PendingDB>(
        IDB_PENDING_STORE_NAME,
        this.idbVersion,
        {
          upgrade(db) {
            db.createObjectStore(IDB_PENDING_STORE_NAME, {
              keyPath: "idbKey",
            });
          },
          terminated() {
            console.warn(
              "IndexedDB connection terminated. Need to reload to enable offline recovery"
            );
          },
        }
      );

      if (!this.isIDBStoreAvailable()) {
        console.error("Failed to initialise IndexedDB");
      }
    }

    if (this.isIDBStoreAvailable()) {
      this.state = BrowserStorageState.INDEXED_DB;
    } else {
      this.state = this.isLocalStorageAvailable()
        ? BrowserStorageState.LOCAL_STORAGE
        : BrowserStorageState.NONE;
    }
  }

  /**
   * Helper method to check if the IndexedDB object store was loaded properly.
   */
  private isIDBStoreAvailable() {
    return (
      this.idb && this.idb.objectStoreNames.contains(IDB_PENDING_STORE_NAME)
    );
  }

  // well yes it's a function to test localStorage why not
  private isLocalStorageAvailable() {
    try {
      const storage = window.localStorage;
      storage.setItem("hello", "rishi");
      storage.removeItem("hello");
      return true;
    } catch (_e) {
      return false;
    }
  }

  /**
   * Load stored snapshots from the browser storage for current work.
   *
   * @returns An array of pending snapshots. An empty array if there are no
   * snapshots or the browser storage is not available or throws an error.
   */
  async loadStoredSnapshots(): Promise<PendingSnapshot[]> {
    try {
      let snapshots: PendingSnapshot[] = [];
      switch (this.state) {
        case BrowserStorageState.LOCAL_STORAGE:
          snapshots = this.loadSnapshotsFromLocalStorage();
          break;

        case BrowserStorageState.INDEXED_DB:
          snapshots = await this.loadSnapshotsFromIdb();
          break;

        default:
          break;
      }
      return snapshots;
    } catch (error) {
      console.error("Failed to load stored snapshots:", error);
      return [];
    }
  }

  private loadSnapshotsFromLocalStorage() {
    const keys = Object.keys(localStorage);
    const pendingSnapshotsKeys = keys.filter((key) =>
      key.includes(`${this.workId}:pending-snapshot`)
    );
    const pendingSnapshots = pendingSnapshotsKeys.flatMap((key) => {
      const value = localStorage.getItem(key);
      const snapshot = value ? pendingSnapshotParser(value) : null;
      return snapshot ?? [];
    });
    return pendingSnapshots;
  }

  private async loadSnapshotsFromIdb() {
    const pendingSnapshots =
      (await this.idb?.getAll(IDB_PENDING_STORE_NAME)) ?? [];
    return pendingSnapshots.filter((snapshot) => {
      if (!snapshot?.idbKey || !Array.isArray(snapshot?.idbKey)) return false;
      return snapshot.idbKey[0] === this.workId;
    });
  }

  /**
   * Stores a snapshot in the browser storage if the Browser Storage is
   * available. Otherwise, it's a no-op. Swallows all errors.
   */
  async storeSnapshot(pending: PendingSnapshot) {
    try {
      switch (this.state) {
        case BrowserStorageState.LOCAL_STORAGE:
          setItem(
            `${this.workId}:pending-snapshot`,
            `${pending.answerBlockId}`,
            JSON.stringify(pending)
          );
          break;

        case BrowserStorageState.INDEXED_DB:
          await this.idb?.put(IDB_PENDING_STORE_NAME, {
            ...pending,
            idbKey: [this.workId, pending.answerBlockId],
          });
          break;

        default:
          break;
      }
    } catch (error) {
      console.error(`Failed to store snapshot in the browser storage:`, error);
      return;
    }
  }

  /**
   * Removes snapshots passed as an argument from the browser storage.
   */
  async removeSnapshots(snapshots: PendingSnapshot[]) {
    for (const snapshot of snapshots) {
      this.removeSnapshot(snapshot.answerBlockId);
    }
  }

  /**
   * Removes a snapshot for current work and specific answer block.
   * If the snapshot doesn't exist, nothing happens.
   */
  async removeSnapshot(answerBlockId: string) {
    try {
      switch (this.state) {
        case BrowserStorageState.LOCAL_STORAGE:
          removeItem(`${this.workId}:pending-snapshot`, answerBlockId);
          break;

        case BrowserStorageState.INDEXED_DB:
          await this.idb?.delete(IDB_PENDING_STORE_NAME, [
            this.workId,
            answerBlockId,
          ]);
          break;

        default:
          break;
      }
    } catch (_error) {
      return;
    }
  }

  /**
   * Recovers and updates snapshots from the browser storage, ensuring that only
   * newer snapshots are processed and integrated with the local Redux store.
   *
   * Since it forcefully updates the local snapshots and pending queue,
   * should be called with caution.
   *
   * @param savedSnapshots - The snapshots that were confirmed to be saved
   * @param dispatch - AppDispatch function
   * @returns An array of snapshots that were recovered. Returns an empty array
   * if any error occurs, all errors handled silently inside methods.
   */
  async recoverPendingSnapshots(
    savedSnapshots: Record<string, Snapshot>,
    dispatch: AppDispatch
  ) {
    const storedSnapshots = await this.loadStoredSnapshots();

    const snapshots = storedSnapshots.filter(({ answerBlockId, snapshot }) => {
      const currentSnapshot = savedSnapshots[answerBlockId];
      return !currentSnapshot || currentSnapshot.version < snapshot.version;
    });

    snapshots.forEach(({ answerBlockId, snapshot }) => {
      dispatch(
        setPendingSnapshot({
          answerBlockId,
          snapshot,
          updateLocalSnapshot: true,
        })
      );
    });

    this.removeSnapshots(storedSnapshots);

    return snapshots;
  }
}
