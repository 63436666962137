import {
  CoreEditor,
  EditorState,
  getVersion,
  JSONContent,
  sendableSteps,
  Step,
} from "@vericus/cadmus-editor-prosemirror";

import { debounce } from "ts-debounce";

import {
  __GLOBAL_CLIENT_ID,
  __GLOBAL_TENANT,
  __GLOBAL_WORK_ID,
} from "@/client/globals";
import { connection } from "@/client/phoenix";
import { API_ENDPOINT } from "@/config";
import { Snapshot } from "@/stores/snapshot";

// Constants
const SAVE_DEBOUNCE = 1000;

/** Network communication state of a Stream Connection */
enum CommState {
  INIT,
  POLL,
  SEND,
}

/** Connection setup arguments */
interface StreamConnectionArgs {
  /** Stream UUID to connect to. */
  streamId: string;
  /** Editor instance manage. */
  editor: CoreEditor;
  /** Callback to update local snapshots. */
  onUpdateSnapshot: (snapshot: Snapshot) => void;
}

/**
 * Connect a `Editor` to an Authority Stream.
 *
 * This connection will:
 *
 *  1. Load the initial state of the Editor by catching up to the latest steps.
 *
 *  2. Regularly poll the Authority Stream for new steps.
 *
 *  3. Send local steps to the Authority server to append to the stream and
 *     handle the re-basing on conflicts.
 *
 *  4. Generate snapshots for confirmed versions of the EditorState using the
 *     `setPendingSnapshot` Redux action.
 *
 * The Stream `args.streamId` must be initialised already using
 * `setupAuthorityStream`.
 *
 * Hooks will be attached to the `editor` instance and cleaned up when it is
 * destroyed.
 *
 * @param args -  connection setup arguments.
 * @returns Promise that ensures the editor's initial state is is caught up to
 *     the latest Stream version.
 */
export async function connectAuthorityStream(
  args: StreamConnectionArgs
): Promise<void> {
  const { editor, streamId, onUpdateSnapshot } = args;
  let comm: CommState = CommState.INIT;

  function receiveSteps(
    steps: readonly Step[],
    clientIds: string[],
    version: number,
    onNewState?: (state: EditorState) => void
  ) {
    if (steps.length === 0) return;
    if (getVersion(editor.state) !== version) {
      console.error(
        "Received steps but editor has moved on from version",
        version
      );
      return;
    }
    editor.commands.receiveSteps(steps, clientIds, onNewState);
  }

  // Catch up on server confirmed steps if needed
  async function catchup() {
    const { steps, version } = await readSteps(
      streamId,
      getVersion(editor.state)
    );
    receiveSteps(
      steps.map((s) => Step.fromJSON(editor.schema, s.data)),
      steps.map((s) => s.client_id),
      version
    );
  }

  async function load() {
    await catchup();
    const sendable = sendableSteps(editor.state);
    // eslint-disable-next-line no-console
    console.assert(
      sendable === null,
      "Sendable steps should be empty after load",
      sendable
    );
  }

  async function poll() {
    if (comm === CommState.SEND) return;
    await catchup();
  }

  function saveSnapshot(editorState: EditorState) {
    const snapshot = {
      version: getVersion(editorState),
      answerDoc: editorState.doc.toJSON(),
    };
    onUpdateSnapshot(snapshot);
  }

  async function sendSteps() {
    const sendable = sendableSteps(editor.state);
    if (sendable) {
      comm = CommState.SEND;

      const transaction = {
        version: sendable.version,
        steps: sendable.steps,
        client_id: sendable.clientID.toString(),
      };
      const result = await appendSteps(streamId, transaction);
      if (result.status === "ok") {
        receiveSteps(
          transaction.steps,
          transaction.steps.map(() => transaction.client_id),
          transaction.version,
          saveSnapshot
        );
        comm = CommState.POLL;
      }
      if (result.status === "conflict") {
        comm = CommState.POLL;
        await poll();
      }
    }
  }

  let pollUnsubscribe: VoidFunction | undefined = undefined;
  async function listen() {
    if (connection.channel) {
      const ref = connection.channel.on(
        "editor.version",
        (payload: { version: number; stream_id: string }) => {
          if (
            payload.stream_id === streamId &&
            payload.version > getVersion(editor.state)
          ) {
            poll();
          }
        }
      );
      pollUnsubscribe = () => {
        connection.channel?.off("editor.version", ref);
      };
    }
  }

  function clean() {
    pollUnsubscribe?.();
  }

  // Listen to changes
  editor.on("create", listen);

  // Save local changes
  const debouncedSendSteps = debounce(sendSteps, SAVE_DEBOUNCE, {
    maxWait: SAVE_DEBOUNCE * 4,
  });
  editor.on("update", debouncedSendSteps);

  // Publish cursor changes
  editor.on("selectionUpdate", ({ editor }) => {
    if (connection.channel) {
      connection.channel.push("editor.selection", {
        stream_id: streamId,
        client_id: __GLOBAL_CLIENT_ID.current,
        selection: editor.state.selection.toJSON(),
      });
    }
  });

  // And cleanup when done
  editor.on("destroy", clean);

  // Begin by catching up to the latest state.
  await load();
}

interface Transaction {
  version: number;
  steps: readonly Step[];
  client_id: string;
}

interface AppendResult {
  status: "ok" | "conflict" | "error";
}

interface ReadStepsResult {
  steps: ServerStep[];
  version: number;
}

interface ServerStep {
  /** Step Data */
  data: unknown;
  /** Client ID that sent the step */
  client_id: string;
}

interface Stream {
  stream_id: string;
  version: number;
  initial_state: JSONContent;
}

/**
 * Ensure a Stream is setup for an Answer Block.
 *
 * @param answerBlockId - The Answer Block ID to use as the Stream ID.
 * @param initialState - The initial state of the Stream at version 0.
 * @returns Promise that resolves to the Stream object.
 *
 * @throws Error if the Stream could not be created. In this case it's better to
 *     reload the application.
 */
export async function setupAuthorityStream(
  answerBlockId: string,
  initialState: JSONContent
): Promise<Stream> {
  const headers = new Headers({
    "x-cadmus-role": "student",
    "x-cadmus-tenant": __GLOBAL_TENANT.current || "",
    "Content-Type": "application/json",
    Accept: "application/json",
  });

  const resp = await fetch(`${API_ENDPOINT}/api/authority/streams`, {
    method: "POST",
    headers,
    credentials: "include",
    body: JSON.stringify({
      stream_id: answerBlockId,
      initial_state: initialState,
    }),
  });

  if (resp.ok) {
    const stream: Stream = await resp.json();
    return stream;
  }

  throw new Error("StreamCreationError");
}

async function appendSteps(
  streamId: string,
  transaction: Transaction
): Promise<AppendResult> {
  const headers = new Headers({
    "x-cadmus-role": "student",
    "x-cadmus-tenant": __GLOBAL_TENANT.current || "",
    "Content-Type": "application/json",
  });

  const body = { transaction, work_id: __GLOBAL_WORK_ID.current ?? undefined };

  const resp = await fetch(
    `${API_ENDPOINT}/api/authority/transactions/${streamId}`,
    {
      method: "POST",
      headers,
      credentials: "include",
      body: JSON.stringify(body),
    }
  );

  if (resp.ok && resp.status === 204) {
    return { status: "ok" };
  }

  if (resp.status === 409) {
    return { status: "conflict" };
  }

  return { status: "error" };
}

async function readSteps(
  streamId: string,
  version: number
): Promise<ReadStepsResult> {
  const headers = new Headers({
    "x-cadmus-role": "student",
    "x-cadmus-tenant": __GLOBAL_TENANT.current || "",
    Accept: "application/json",
  });

  const resp = await fetch(
    `${API_ENDPOINT}/api/authority/steps/${streamId}?version=${version}`,
    {
      method: "GET",
      headers,
      credentials: "include",
    }
  );

  if (resp.ok) {
    const result: ReadStepsResult = await resp.json();
    return result;
  }

  return { steps: [], version };
}
