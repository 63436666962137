import { styled } from "styled-components";

import { colors } from "@vericus/cadmus-ui";

export const QuestionWrapper = styled.div`
  width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 45px;
`;

export const CanvasWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 1px solid ${colors.navy300};
  border-radius: 8px;
  width: 100%;
`;

export const FormWrapper = styled.div`
  width: 790px;
`;

