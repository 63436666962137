import { Fragment, useCallback } from "react";
import { styled } from "styled-components";

import { Radio, Text } from "@vericus/cadmus-ui";

import { useAppDispatch, useAppSelector } from "@/data/hooks";
import { selectAnswerSnapshot, setLocalSnapshot } from "@/features/authority";
import { Snapshot } from "@/stores/snapshot";

import * as styles from "./answer-form.css";

interface Props {
  /** Field identifier */
  identifier: string;
  /** Current Answer Block ID */
  answerBlockId: string;
  /** Make the choices readonly */
  isReadonly?: boolean;
}

/**
 * Answer response form section to respond to a True/False choice question.
 */
export function BooleanResponseForm(props: Props) {
  const { identifier, answerBlockId } = props;

  const dispatch = useAppDispatch();
  const snapshot = useAppSelector((state) =>
    selectAnswerSnapshot(state, answerBlockId)
  );
  const version = snapshot?.version ?? 0;
  const answerIsTrue = getAnswerBoolean(snapshot, props.identifier);

  // Callback to update the local snapshot on every change
  const setBoolean = useCallback(
    (value: boolean) => {
      const newVersion = version + 1;
      const snapshot: Snapshot = {
        version: newVersion,
        answerBoolean: value,
        answerFields: { [identifier]: [value.toString()] },
      };
      dispatch(
        setLocalSnapshot({
          answerBlockId,
          snapshot,
        })
      );
    },
    [dispatch, version, answerBlockId, identifier]
  );

  const choices = [
    { label: "True", checked: answerIsTrue === true, value: true },
    { label: "False", checked: answerIsTrue === false, value: false },
  ];

  return (
    <div className={styles.optionResponseForm}>
      {choices.map((choice, index) => (
        <Fragment key={`${choice.value}-${index}`}>
          <div className={styles.optionContainer}>
            <div
              className={styles.option[choice.checked ? "selected" : "default"]}
            >
              <StyledRadioButton
                name={`${answerBlockId}-${choice.value}`}
                colorVariant="primary"
                contentEditable={false}
                value={choice.value.toString()}
                checked={choice.checked}
                onChange={() => setBoolean(choice.value)}
                disabled={props.isReadonly}
              >
                <Text kind="bodyLine" color="navy700">
                  {choice.label}
                </Text>
              </StyledRadioButton>
            </div>
          </div>
        </Fragment>
      ))}
    </div>
  );
}

function getAnswerBoolean(
  snapshot: Snapshot | undefined,
  identifier: string
): boolean | undefined {
  if (snapshot?.answerFields) {
    const values = snapshot.answerFields[identifier];
    if (values && values.length > 0) {
      return values[0] === "true";
    }
    return undefined;
  }
  return snapshot?.answerBoolean;
}

// Needed so the label takes up whole parent `div` area and becomes more accessible
const StyledRadioButton = styled(Radio)`
  width: 100%;
  margin: 0;
  padding: 12px 16px;
`;
