import { isHangingIndent, referencesTitle } from "@vericus/cadmus-common";
import {
  CadmusEditorOptions,
  CoreEditor,
  createCadmusEditor,
  Editor,
  getVersion,
  JSONContent,
  Sendable,
  sendableSteps,
} from "@vericus/cadmus-editor-prosemirror";

import { trackEditorEvent } from "@/client/events";
import {
  __GLOBAL_ASSESSMENT_ID,
  __GLOBAL_CLIENT_ID,
  __GLOBAL_STUDENT_ID,
  __GLOBAL_TENANT,
  __GLOBAL_WORK_ID,
} from "@/client/globals";
import { connection } from "@/client/phoenix";
import GLOBAL_SUGGESTION_CLIENT from "@/client/suggestion";
import { API_ENDPOINT } from "@/config";
import { AppDispatch } from "@/data/store";
import {
  connectAuthorityStream,
  LocalCursorPresence,
  SavingMechanism,
  setPendingSnapshot,
  setupAuthorityStream,
} from "@/features/authority";

import { Snapshot } from "../snapshot";
import { setActiveEditor } from "./active-editor";
import defaultNotesExam from "./default-notes-exam.json";
import defaultNotes from "./default-notes.json";
import { ClassicEditorName } from "./types";

interface NewAnswerEditorArgs {
  /** Answer Block that the editor is connected to. */
  answerBlockId: string;
  /** Name of the classic editor if it is one. */
  editorName: ClassicEditorName | null;
  /** Initial content. */
  content: JSONContent | null;
  /** Initial content's version */
  version: number;
  /** Redux dispatcher */
  dispatch: AppDispatch;
  /** Assessment settings from the Sheet. */
  sheetProps: SheetProps;
  /** Extra override Cadmus Editor initialisation options. */
  editorOpts?: Partial<CadmusEditorOptions>;
}

/** Instruction Sheet information providing additional setup for the editors. */
export type SheetProps = {
  savingMechanism: SavingMechanism;
  isExam: boolean;
  referencingStyle: string | null;
  isMultiFormat: boolean;
  isLockDownExam: boolean;
  assessmentName: string;
};

/**
 * Create a new Editor from a snapshot or empty state and optionally connect it
 * to a collaborative Authority Stream.
 *
 * NOTE: currently the connection to a Stream is only done for Classic Editors
 * (args.editorName will not be null), tied to an Answer Block(
 * `args.sheetProps.savingMechanism` will be `SavingMechanism.BLOCK`).
 *
 * @param args - Editor initialisation args
 * @returns a Promise resolving to the initialised Editor with its contents.
 *      Wait on this promise before allowing the user to make edits to the
 *      Editor.
 */
export async function newAnswerEditor(
  args: NewAnswerEditorArgs
): Promise<Editor> {
  const {
    answerBlockId,
    version,
    editorName,
    content,
    dispatch,
    sheetProps,
    editorOpts,
  } = args;

  // Use collaboration authority based saving if a Classic Work is being saved
  // as BLOCKS.
  const withCollabAuthority =
    sheetProps.savingMechanism === SavingMechanism.BLOCK && editorName !== null;

  const onSave = (editor: CoreEditor) => {
    const sendable = sendableSteps(editor.state);
    if (sendable) {
      selfConfirmSteps(editor, sendable);
      const snapshot = {
        version: getVersion(editor.state),
        answerDoc: editor.getJSON(),
      };
      const payload = { answerBlockId, snapshot, updateLocalSnapshot: true };
      // And queue it for saving
      dispatch(setPendingSnapshot(payload));
    }
  };

  // TODO: Add a streamUUID edge to AnswerBlock type indicating that the
  // AnswerBlock is meant to collaborate using a specific Authority Stream.
  // Will be resolved in CDMS-2503.
  const streamId = answerBlockId;

  // Current editor contents
  let editorContent: JSONContent | null = content;
  // Classic editors get a custom initial content at version 0
  if (editorName && version === 0) {
    editorContent = defaultContent(editorName, sheetProps);
    // Ensure the collaboration stream is created with the same initial content
    if (withCollabAuthority) {
      await setupAuthorityStream(streamId, editorContent);
    }
  }

  const editor = createCadmusEditor({
    editorId: editorName ?? answerBlockId,
    editorA11yLabel: "Student answer",
    editable: true,
    content: editorContent,
    version,
    clientId: __GLOBAL_CLIENT_ID.current,
    cursorPresence: connection.presence
      ? new LocalCursorPresence(streamId, connection.presence)
      : undefined,
    suggestionClient: GLOBAL_SUGGESTION_CLIENT,
    imageAPIBase: `${API_ENDPOINT}/api/work_media`,
    tenant: __GLOBAL_TENANT.current ?? undefined,
    assessmentId: __GLOBAL_ASSESSMENT_ID.current ?? undefined,
    workId: __GLOBAL_WORK_ID.current ?? undefined,
    userId: __GLOBAL_STUDENT_ID.current ?? undefined,
    userRole: "student",
    contentPlaceholder: "Write your answer here…",
    autofocus: true,
    enablePasteAnnotations: true,
    onEditorEvent: trackEditorEvent,
    cursorBottomPadding: 144,
    onSave: withCollabAuthority
      ? undefined
      : (_editorId, editor) => onSave(editor),
    ...editorOpts,
  });

  editor.on("focus", () => {
    setActiveEditor(answerBlockId, editor, editorName);
  });

  if (withCollabAuthority) {
    await connectAuthorityStream({
      streamId,
      editor,
      onUpdateSnapshot: (snapshot) =>
        dispatch(
          setPendingSnapshot({
            answerBlockId,
            snapshot,
            updateLocalSnapshot: true,
          })
        ),
    });
  }

  return editor;
}

// Auto confirm steps
function selfConfirmSteps(editor: CoreEditor, sendable: Sendable) {
  const clientIds = sendable.steps.map(() => sendable.clientID);
  editor.commands.receiveSteps(sendable.steps, clientIds);
}

/**
 * Create a Editor from snapshots of classic editor.
 */
export async function newClassicEditor(
  editorName: ClassicEditorName,
  answerBlockId: string,
  snapshot: Snapshot | null,
  dispatch: AppDispatch,
  sheetProps: SheetProps
): Promise<Editor> {
  return await newAnswerEditor({
    editorName,
    answerBlockId,
    content: snapshot?.answerDoc ?? null,
    version: snapshot?.version ?? 0,
    dispatch,
    editorOpts: buildClassicEditorOpts(editorName, sheetProps),
    sheetProps,
  });
}

function buildClassicEditorOpts(
  editorName: ClassicEditorName,
  sheetProps: SheetProps
): Partial<CadmusEditorOptions> {
  const { isExam, isLockDownExam, referencingStyle } = sheetProps;

  const enableHangingIndent = isHangingIndent(referencingStyle);

  const commonOpts: Partial<CadmusEditorOptions> = {
    onEditorEvent: trackEditorEvent,
    enableManual: !isExam,
    enableHyperlink: !isLockDownExam,
    enableImage: !isLockDownExam,
    editable: true,
  };

  switch (editorName) {
    case ClassicEditorName.Body:
      return {
        ...commonOpts,
        editorA11yLabel: "Assessment body editor",
        enablePasteAnnotations: true,
        titlePlaceholder: "Give your work a title",
        contentPlaceholder: "Write your submission here...",
        autofocus: false,
        minBlocks: 2,
        enableFootnoting: true,
      };
    case ClassicEditorName.Notes:
      return {
        ...commonOpts,
        editorA11yLabel: "Assessment notes editor",
        titlePlaceholder: "Notes...",
        contentPlaceholder:
          "Use this space to plan, take notes, or work through your checklist. Your notes won’t be submitted with your work.",
        autofocus: false,
      };
    case ClassicEditorName.References:
      return {
        ...commonOpts,
        editorA11yLabel: "Assessment references editor",
        titlePlaceholder: "References...",
        referencesPlaceholder: true,
        enableHangingIndent: enableHangingIndent ?? false,
        autofocus: false,
      };

    default:
      return commonOpts;
  }
}

function defaultContent(
  editorName: ClassicEditorName,
  sheetProps: SheetProps
): JSONContent {
  switch (editorName) {
    case ClassicEditorName.Body:
      return defaultBodyContent(sheetProps.assessmentName);
    case ClassicEditorName.Notes:
      return defaultNotesContent(sheetProps.isExam);
    case ClassicEditorName.References:
      return defaultReferencesContent(sheetProps.referencingStyle);
  }
}

function defaultBodyContent(assessmentName: string): JSONContent {
  return {
    type: "doc",
    content: [
      {
        type: "heading",
        attrs: { level: 1 },
        content: [{ type: "text", text: assessmentName }],
      },
      {
        type: "paragraph",
      },
    ],
  };
}

function defaultNotesContent(isExam: boolean): JSONContent {
  return isExam ? defaultNotesExam : defaultNotes;
}

function defaultReferencesContent(
  referencingStyle: string | null
): JSONContent {
  return {
    type: "doc",
    content: [
      {
        type: "heading",
        attrs: { level: 1 },
        content: [{ type: "text", text: referencesTitle(referencingStyle) }],
      },
      { type: "paragraph" },
    ],
  };
}
