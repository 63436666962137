import { useState } from "react";
import { styled, keyframes } from "styled-components";

import {
  Colored,
  colors,
  easeOutCirc,
  FocusAnimation,
  levels,
  Text,
} from "@vericus/cadmus-ui";

import ClockSvgIcon from "./ClockSvgIcon";
import CloseSvgIcon from "./CloseSvgIcon";

interface Props {
  /** Main message to render when the clock is open */
  message: string;
  /** Message suffix rendered beside the main message */
  suffix: string;
}

/**
 * PureClock is a React component for rendering the floating clock with
 * a message displayed when the clock is open.
 */
export function PureClock(props: Props) {
  const { message, suffix } = props;
  const [open, setOpen] = useState(true);

  return (
    <Container>
      {open && (
        <Detail role="timer" aria-live="polite" aria-atomic="true">
          <Text kind="systemMd" aria-label="Time remaining">
            {message} <Colored color="grey500">{suffix}</Colored>
          </Text>
        </Detail>
      )}
      <IconButton
        title="Time progress indicator"
        aria-label="Time progress indicator"
        aria-haspopup
        aria-expanded={open}
        tabIndex={0}
        onClick={() => setOpen(!open)}
      >
        {open ? <CloseSvgIcon /> : <ClockSvgIcon />}
      </IconButton>
    </Container>
  );
}

const Container = styled.div`
  display: inline-block;
  position: relative;
`;

const IconButton = styled.button`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  box-sizing: boder-box;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  border: none;
  background: ${colors.black200};
  fill: ${colors.black200};
  color: ${colors.white100};
  box-shadow: ${levels.three};

  cursor: pointer;

  &:not(:disabled) {
    /* The :before pseudo-element creates a focus and ping outline*/
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50%;
      width: 36px;
      height: 36px;
    }

    &:focus:before {
      ${FocusAnimation};
    }

    /* The :after pseudo-element creates a performant box shadow change on hover */
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      box-shadow: ${levels.three};
      opacity: 0;
      transition: opacity 0.6s ${easeOutCirc};
      background: hsla(220, 30%, 40%, 0.17);
      mix-blend-mode: screen;
    }

    &:hover:after,
    &:focus:after {
      opacity: 1;
    }

    &:active:after {
      opacity: 0;
    }

    &:hover:focus:after {
      opacity: 0;
    }

    &:focus:not(:hover):after {
      transition: opacity 1.2s ${easeOutCirc};
    }
  }

  &:disabled {
    opacity: 0.54;
  }
`;

const detailsReveal = keyframes`
  from {
    opacity: 0;
    transform: translateX(9px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

const Detail = styled.div`
  position: absolute;
  right: 100%;
  top: 3px;
  margin-right: 9px;
  background: ${colors.white200};
  box-sizing: border-box;
  padding: 6px 12px;
  white-space: nowrap;
  box-shadow: ${levels.three};
  border-radius: 100px;

  animation: ${detailsReveal} 0.3s ease;
  transform: translateZ(0); /* Animation optimisation */
`;
